import { mapState, mapActions } from 'pinia'
import { useStaffStore } from '~/stores/staff.js'

export default {
    computed: {
        ...mapState(useStaffStore, ['staff']),
        staffOptions () {
            return this.staff.map((staff) => {
                return {
                    value: staff.uuid,
                    label: staff.name
                }
            })
        },
        staffWithUserOptions () {
            return this.staff
                .filter(s => !!s.user)
                .map((staff) => {
                    return {
                        value: staff.uuid,
                        label: staff.name
                    }
                })
        }
    },

    created () {
        this.fetchStaff()
    },

    methods: {
        ...mapActions(useStaffStore, ['fetchStaff'])
    }
}
