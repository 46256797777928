import { defineStore } from 'pinia'
import StaffRepository from '~/repositories/Creche/StaffRepository.js'

export const useStaffStore = defineStore('staff', {
    state: () => ({
        staff: []
    }),

    actions: {
        fetchStaff (crecheUuid = null) {
            if (this.staff.length) {
                return Promise.resolve(this.staff)
            }

            return StaffRepository.index({}, crecheUuid)
                .then((response) => {
                    this.staff = response.data
                })
        }
    }
})
